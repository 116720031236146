(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name AppController
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('AppController', AppController);

    AppController.$inject = ["propuestaService","$timeout","$location",'$anchorScroll'];

    function AppController(propuestaService,$timeout,$location,$anchorScroll)
    {
    	console.log("AppController Enabled");
		var vm = this;
        vm.noregShow = false;
		vm.propuesta = propuestaService;
		vm.propuestaError = false;
		vm.propuestaOk = false;
        vm.likes={};
        vm.dislikes = {};

        vm.goForm =function()
        {
            $location.hash("propuestaForm");
            $anchorScroll();

        }

        vm.voteDislike = function(propuesta_id)
        {
            vm.propuesta.vote({"propuesta_id":propuesta_id,"positive":0}).then(function(data)
            {
                 console.log(data);
                if(data.code == 200){
                    vm.dislikes[propuesta_id] = data.pcount;
                    vm.propuestaOk = true;
                    $timeout(function()
                    {
                        vm.propuestaOk = false;
                    },5000);
                }else{
                    vm.propuestaError = true;
                    $timeout(function()
                    {
                        vm.propuestaError = false;
                    },5000);
                }
            });
        };

		vm.vote = function(propuesta_id)
		{
			vm.propuesta.vote({"propuesta_id":propuesta_id,"positive":1}).then(function(data)
			{
				 console.log(data);
                if(data.code == 200){
                    vm.likes[propuesta_id] = data.pcount;
                    vm.propuestaOk = true;
                    $timeout(function()
                    {
                        vm.propuestaOk = false;
                    },5000);
                }else{
                    vm.propuestaError = true;
                    $timeout(function()
                    {
                        vm.propuestaError = false;
                    },5000);
                }
			});
		};

    }


})();