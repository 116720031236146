(function(){
    angular
        .module('app')
        .directive('addthis', addthisDirective);

    addthisDirective.$inject = ['$timeout'];

    function addthisDirective($timeout){
        return {
       	    restrict: 'E',
            templateUrl: '/app/views/addthis.html',
            link: function (scope, element, $attrs) {
            	scope.getUri =  function()
            	{
            		return domain+slugPropuesta;
            	};
            	$timeout(function(){
            		addthis.toolbox('.addthis_toolbox');
            	},100);
                
            }
        };
    }
})();