(function() {
    'use strict';
    /**
     * @memberof app
     *@ngdoc service
     *@name propuestaService
     *@description
     *Servicio de autores
     */

    angular
        .module('app')
        .factory('propuestaService', propuestaService);

    propuestaService.$inject = ["$http","$q","$window","$location"];

    function propuestaService($http,$q,$window,$location) {  
        console.log("app.propuesta Servicio ENABLE");

        var propuesta = {};


        propuesta.getPropuestas = function(reto_id,offset,order,search)
        {
            var url= '/api/v1/propuestas';
             return $http({
                method  : 'GET',
                url     : url,
                params    : {
                    "reto_id":reto_id,
                    "offset":offset,
                    "order":order,
                    "search":search
                }
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  
                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };

                /**
        * @ngdoc method
        * @name login
        * @memberof propuestaService
        * @desciption
        * loguea a un usuario
        */
        propuesta.vote = function (data){
            var url= '/api/v1/vote';
             return $http({
                method  : 'POST',
                url     : url,
                data    : {
                    "VoteForm":data                    
                }
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  
                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };        

                /**
        * @ngdoc method
        * @name login
        * @memberof propuestaService
        * @desciption
        * loguea a un usuario
        */
        propuesta.propuesta = function (data){
            var url= '/api/v1/propuesta';
             return $http({
                method  : 'POST',
                url     : url,
                data    : {
                    "PropuestaForm":data                    
                }
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  
                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };

       /**
        * @ngdoc method
        * @name coment
        * @memberof propuestaService
        * @desciption
        * loguea a un usuario
        */
        propuesta.comentario = function (data){
            var url= '/api/v1/coment';
             return $http({
                method  : 'POST',
                url     : url,
                data    : {
                    "ComentarioForm":data                    
                }
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  
                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };



        


        return propuesta;
    	
    }

})();