(function() {
    'use strict';


    angular.module('app',['altmod.login','altmod.account','altmod.register','ngRoute','addthis','lr.upload','infinite-scroll','ngAnimate','ngTouch','ui.bootstrap']).config(function($interpolateProvider){
    $interpolateProvider.startSymbol('{[{').endSymbol('}]}');
	}).config(function($httpProvider) {
       		// $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        	$httpProvider.defaults.headers.post['X-CSRF-Token'] = jQuery('meta[name="csrf-token"]').attr("content");
     	    $httpProvider.defaults.timeout = 30000;
    	});
})();
