(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name propuestaController
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('propuestaController', propuestaController);

    propuestaController.$inject = ["propuestaService","$window","$timeout","$location",'$anchorScroll','upload'];

    function propuestaController(propuestaService,$window,$timeout,$location,$anchorScroll,upload)
    {
    	console.log("Propuesta is enabled ");
    	var vm = this;
        vm.propuesta = propuestaService;
        vm.data = {};
        vm.data.etiquetas = {};
        vm.coment={};
        vm.loading = false;
        vm.propuestas=[];
        vm.offset=0;
        vm.hideButton = false;
        vm.option = 'id desc';
        vm.search = "";
        vm.file_propuesta = null;
        vm.imagen_propuesta = null;
        vm.data.enlaces_externos ={"filas":[{"titulo":"Enlace 1","blank":false,"enlace":"","icono":"","page_id":""},{"titulo":"Enlace 2","blank":false,"enlace":"","icono":"","page_id":""}]};

        vm.upload = function(response)
        {
            if(response.data.code == 200){
                vm.data.imagen_propuesta = response.data.file_id;
                vm.imagen_propuesta  = response.data.name;
                console.log(response.data.file_id);
            }
        };

        vm.uploadfile = function(response)
        {
            if(response.data.code == 200){
                vm.data.file_propuesta = response.data.file_id;
                vm.file_propuesta =  response.data.name;
                console.log(response.data.file_id);
            }
        };

        vm.resetPropuestas = function()
        {
            vm.offset=0;
            vm.propuestas=[];
            vm.hideButton = false;
            vm.loading = false;
            //vm.search = "";
        };

        vm.getPropuestas = function(reto_id)
        {
            if(vm.loading)
                return;

            if(vm.hideButton)
                return;
            
            vm.loading = true;

                vm.propuesta.getPropuestas(reto_id,vm.offset,vm.option,vm.search).then(function(data)
                {
                    vm.loading =false;
                    if(data.length){
                        vm.offset++;    
                        vm.propuestas = vm.propuestas.concat(data);
                        if(data.length<8)
                            vm.hideButton= true;
                    }else{
                        vm.hideButton= true;
                    }
                });
            

        };

        vm.comentario = function(form)
        {
            console.log("propuesta function");
            if(!form.$valid)
                return false;

            console.log("comentario Form");

            return vm.propuesta.comentario(vm.coment).then(function(data)
            {
                console.log(data);
                if(data.code == 200){
                    
                    $window.location.reload();
                }else{
                    vm.propuestaError = true;
                    $timeout(function()
                    {
                        vm.propuestaError = false;
                    },5000);
                }

            });
        };

        vm.crear = function(form)
        {
            console.log("propuesta function");
            if(!form.$valid)
                return false;

            console.log("register user");

            return vm.propuesta.propuesta(vm.data).then(function(data)
            {
                console.log(data);
                if(data.code == 200){
                    
                    $window.location.reload();
                }else{
                    vm.propuestaError = true;
                    $timeout(function()
                    {
                        vm.propuestaError = false;
                    },5000);
                }

            });
        };

    }


})();